import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Store } from '../context/GlobalStore';
import { getCurrentUser } from '../api/auth/cognito';
export default function Auth(props) {
    const { globalState, setGlobalState } = useContext(Store);
    const currentUser = getCurrentUser();
    const next = window.location.pathname + window.location.search;
    const nextRedirect = _jsx(Redirect, { to: `/signin?next=${next}` });
    if (globalState.isTimeout) {
        setGlobalState({ ...globalState, isTimeout: false });
        return nextRedirect;
    }
    return currentUser ? props.children : nextRedirect;
}
