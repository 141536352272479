export const smoothScroll = () => {
    const scrollY = window.pageYOffset;
    let position = 0;
    let progress = 0;
    const move = function () {
        progress++;
        position = scrollY - scrollY * (progress / 10);
        window.scrollTo(0, position);
        if (position > 0) {
            requestAnimationFrame(move);
        }
    };
    requestAnimationFrame(move);
};
