import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Container, Typography } from '@material-ui/core';
import React from 'react';
import { Head, Main, Root, TopAppBarSpacer } from '../../components/Shared';
export default function ErrorFallback() {
    return (_jsxs(Root, { children: [_jsx(Head, { title: "error" }), _jsx(Main, { children: _jsxs(Container, { maxWidth: "lg", children: [_jsx(TopAppBarSpacer, {}), _jsx(Typography, { variant: "h5", style: {
                                marginBottom: 20,
                            }, children: "\u30B7\u30B9\u30C6\u30E0\u30A8\u30E9\u30FC\u304C\u767A\u751F\u3057\u307E\u3057\u305F" }), _jsx(Typography, { style: {
                                marginBottom: 20,
                            }, children: "\u6642\u9593\u3092\u7F6E\u3044\u3066\u304B\u3089\u518D\u5EA6\u30A2\u30AF\u30BB\u30B9\u3057\u3066\u304F\u3060\u3055\u3044" })] }) })] }));
}
