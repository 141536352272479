import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import axios from 'axios';
import React, { createContext, useEffect, useReducer, useState, } from 'react';
import { ACCOUNT_API_END_POINT } from '../api/api';
import { getSession } from '../api/auth/cognito';
export const ROLE_ADMIN = 0;
export const ROLE_NORMAL = 1;
const initialState = {
    session: undefined,
};
export const Store = createContext({});
export const Provider = ({ children }) => {
    var _a;
    const [globalState, setGlobalState] = useReducer((prev, newState) => {
        const _newState = typeof newState === 'function' ? newState(prev) : newState;
        return { ...prev, ..._newState };
    }, initialState);
    const [initializing, setInitializing] = useState(true);
    useEffect(() => {
        getSession((err, session) => {
            if (!session) {
                setInitializing(false);
                return;
            }
            axios
                .get(`${ACCOUNT_API_END_POINT}/me`, {
                headers: {
                    Authorization: session.getIdToken().getJwtToken(),
                },
            })
                .then((response) => {
                setGlobalState({
                    session: {
                        idToken: session.getIdToken().getJwtToken(),
                        accessToken: session.getAccessToken().getJwtToken(),
                        refreshToken: session.getRefreshToken().getToken(),
                    },
                    signedUser: response.data,
                });
                setInitializing(false);
            })
                .finally(() => {
                setInitializing(false);
            });
        });
    }, [(_a = globalState.session) === null || _a === void 0 ? void 0 : _a.idToken]);
    // セッションが切れるのを防ぐために裏でセッションを更新する
    useEffect(() => {
        const oldSession = globalState === null || globalState === void 0 ? void 0 : globalState.session;
        const refreshSession = () => {
            getSession((err, session) => {
                if (!session) {
                    setGlobalState({
                        ...globalState,
                        isTimeout: true,
                    });
                    return;
                }
                console.log('refreshSession', session);
                const newIdToken = session.getIdToken().getJwtToken();
                const newAccessToken = session.getAccessToken().getJwtToken();
                const newRefreshToken = session.getRefreshToken().getToken();
                if ((oldSession === null || oldSession === void 0 ? void 0 : oldSession.idToken) !== newIdToken ||
                    (oldSession === null || oldSession === void 0 ? void 0 : oldSession.accessToken) !== newAccessToken ||
                    (oldSession === null || oldSession === void 0 ? void 0 : oldSession.refreshToken) !== newRefreshToken) {
                    setGlobalState({
                        session: {
                            idToken: session.getIdToken().getJwtToken(),
                            accessToken: session.getAccessToken().getJwtToken(),
                            refreshToken: session.getRefreshToken().getToken(),
                        },
                        signedUser: globalState.signedUser,
                    });
                }
            });
        };
        const timer = setInterval(refreshSession, 1000 * 60);
        window.addEventListener('focus', refreshSession);
        return () => {
            clearInterval(timer);
            window.removeEventListener('focus', refreshSession);
        };
    }, [globalState === null || globalState === void 0 ? void 0 : globalState.session, globalState.signedUser]);
    return (_jsx(Store.Provider, { value: { globalState, setGlobalState }, children: initializing ? null : children }));
};
