import axios from 'axios';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';
const cache = {};
export const clearShopCache = (shopId) => {
    delete cache[shopId];
};
export default function useShop(clientId, shopId, startDate, endDate) {
    var _a;
    const { globalState, setGlobalState } = useContext(Store);
    const [shop, setShop] = useState(cache[shopId || '']);
    const [loading, setLoading] = useState(cache[shopId || ''] == undefined);
    const reload = () => {
        var _a, _b;
        if (!shopId || !((_a = globalState.session) === null || _a === void 0 ? void 0 : _a.idToken)) {
            return;
        }
        const params = startDate && endDate
            ? {
                startDate: dayjs(startDate).format('YYYY-MM-DD'),
                endDate: dayjs(endDate).format('YYYY-MM-DD'),
            }
            : {};
        axios
            .get(`${API_END_POINT}/app/clients/${clientId}/shops/${shopId}`, {
            params,
            headers: {
                Authorization: (_b = globalState.session) === null || _b === void 0 ? void 0 : _b.idToken,
            },
        })
            .then((response) => {
            setShop(response.data.shop);
            cache[shopId] = response.data.shop;
        })
            .finally(() => {
            setLoading(false);
        });
    };
    useEffect(() => {
        reload();
    }, [
        clientId,
        shopId,
        startDate === null || startDate === void 0 ? void 0 : startDate.getTime(),
        endDate === null || endDate === void 0 ? void 0 : endDate.getTime(),
        (_a = globalState.session) === null || _a === void 0 ? void 0 : _a.idToken,
    ]);
    return [shop, loading, reload];
}
