import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import Link from '@material-ui/core/Link';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
export default function LinkRouter(props) {
    const newProps = {
        ...props,
        children: props.children || '',
    };
    return _jsx(Link, { ...newProps, component: RouterLink });
}
