//original: https://github.com/CharlesStover/use-react-router/blob/master/src/use-react-router.ts
// このエラー対応のためローカルに保存
// https://adlive.backlog.jp/view/FUJISAN-1206#comment-185888800
import { useContext, useEffect } from 'react';
import { __RouterContext, } from 'react-router';
import useForceUpdate from 'use-force-update';
const INCORRECT_VERSION_ERROR = new Error('use-react-router may only be used with react-router@^5.');
const MISSING_CONTEXT_ERROR = new Error('useReactRouter may only be called within a <Router /> context.');
export default function useRouter() {
    // If this version of react-router does not support Context,
    if (!__RouterContext) {
        throw INCORRECT_VERSION_ERROR;
    }
    // If the react-router Context is not a parent Component,
    const context = useContext(__RouterContext);
    if (!context) {
        throw MISSING_CONTEXT_ERROR;
    }
    const forceUpdate = useForceUpdate();
    useEffect(() => context.history.listen(forceUpdate), [context]);
    return context;
}
