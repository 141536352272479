import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { ListItemIcon, MenuItem, MenuList, TextField, makeStyles, } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete, createFilterOptions, } from '@material-ui/lab';
import React, { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import useWorkspaceSearch from '../api/use-workspace-search';
import { useSizeType } from '../hooks/use-size-type';
import { TabIndeies as ClientTabIndeies } from './ClientSideMenu';
import { TabIndeies as ShopTabIndeies } from './ShopSideMenu';
const useStyles = makeStyles((theme) => ({
    searchBar: {},
    searchBarForSp: {
        '& .MuiAutocomplete-endAdornment': {
            top: 'auto',
        },
    },
    searchBarInputWrapper: {
        position: 'relative',
        fontSize: '14px',
        backgroundColor: '#ffffff',
        borderRadius: '24px',
        border: '1px solid #DDDDDD',
    },
    searchIcon: {
        position: 'absolute',
        top: '50%',
        left: '12px',
        transform: 'translate(0,-50%)',
        fontSize: '24px',
        color: '#707070',
    },
    autoCompleteItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    autoCompleteItemLabel: {
        flex: 1,
    },
    autoCompleteItemRightIcon: {
        color: '#777',
    },
    subMenu: {
        position: 'absolute',
        height: 'auto',
        width: '200px',
        color: '#000',
        border: '1px solid #DDDDDD',
        background: '#FFFFFF',
    },
    subMenuItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: 'inherit',
        textDecoration: 'none',
        width: '100%',
    },
    subMenuLabel: {
        marginLeft: '12px',
    },
}));
const TYPEING_WAIT_MS = 300;
let lastTypedAt = 0;
export const SearchBar = ({ autoFocus = false, }) => {
    const classes = useStyles();
    const searchInput = useRef(null);
    const { history, location } = useReactRouter();
    const [keyword, setKeyword] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [highlightItem, setHighlightItem] = useState(null);
    const { isPcSize } = useSizeType();
    const { searchResults, isLoadingSearchResults } = useWorkspaceSearch(searchKeyword.trim());
    useHotkeys('ctrl+/', () => {
        var _a;
        (_a = searchInput.current) === null || _a === void 0 ? void 0 : _a.focus();
    });
    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => {
                var _a;
                (_a = searchInput.current) === null || _a === void 0 ? void 0 : _a.focus();
            }, 100);
        }
    }, [autoFocus]);
    const handleChange = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event, value) => {
        if (!value) {
            return;
        }
        if (value.type == 'client') {
            history.push(`/a/${value.workspaceUid}/clients/${value.clientId}`);
        }
        else if (value.type == 'shop') {
            history.push(`/a/${value.workspaceUid}/clients/${value.clientId}/shops/${value.shopId}`);
        }
    };
    const handleInputChange = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event, value, reason) => {
        if (reason != 'input') {
            setKeyword('');
            return;
        }
        setKeyword(value);
        // 最後にキーが押された時間を記録
        lastTypedAt = new Date().getTime();
        setTimeout(() => {
            const currentTime = new Date().getTime();
            const isCompletedTyping = currentTime - lastTypedAt >= TYPEING_WAIT_MS;
            // 入力終了なら検索実行
            if (isCompletedTyping) {
                setSearchKeyword(value);
            }
        }, TYPEING_WAIT_MS);
    };
    const handleHighlightChange = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event, option, reason) => {
        var _a, _b, _c, _d;
        if (!event || !option) {
            setHighlightItem(null);
            return;
        }
        // マウスでハイライトされた場合のみサブメニューをサポート
        if (reason === 'mouse') {
            let itemElement = event.target;
            while (itemElement &&
                itemElement.className !== 'MuiAutocomplete-option') {
                itemElement = itemElement.parentElement;
            }
            const containerElement = (_d = (_c = (_b = (_a = itemElement.parentElement) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.parentElement) === null || _c === void 0 ? void 0 : _c.parentElement) === null || _d === void 0 ? void 0 : _d.parentElement;
            if (!containerElement || !itemElement) {
                return;
            }
            const itemRect = itemElement.getBoundingClientRect();
            if (!itemRect) {
                return;
            }
            const size = {
                top: itemRect.top,
                width: containerElement.offsetWidth,
            };
            setHighlightItem({ item: option, size });
        }
    };
    const handleAutoCompleteClose = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event, reason) => {
        var _a;
        if (reason == 'blur' &&
            ((_a = event.relatedTarget) === null || _a === void 0 ? void 0 : _a.className) === classes.subMenuItem) {
            // サブメニューでクリックイベントを受け取るために何もしない
        }
        else {
            setHighlightItem(null);
        }
    };
    // すべての検索結果をマッチさせて表示するために
    // キーワード自体をフィルター対象の文字列とする
    const filterOptions = createFilterOptions({
        stringify: (option) => keyword,
    });
    const getOptionLabel = (result) => {
        if (result.type == 'client') {
            return result.clientName || '不明';
        }
        else if (result.type == 'shop') {
            return result.shopName || '不明';
        }
        else {
            return '不明';
        }
    };
    const getGroupLabel = (result) => {
        if (result.type == 'client') {
            return `${result.workspaceName} / クライアント`;
        }
        else if (result.type == 'shop') {
            return `${result.workspaceName} / 店舗`;
        }
        else {
            return '不明';
        }
    };
    const buildSubMenu = () => {
        // サブメニューはPCサイズのみ表示
        if (!isPcSize) {
            return;
        }
        return (_jsx(SubMenu, { highlightItem: highlightItem, setHighlightItem: setHighlightItem }));
    };
    return (_jsxs("div", { className: classes.searchBarInputWrapper, style: {
            position: 'relative',
            maxWidth: isPcSize ? '500px' : '100%',
            minWidth: isPcSize ? 'auto' : '100%',
        }, children: [_jsx(SearchIcon, { className: classes.searchIcon }), _jsx(Autocomplete, { id: "combo-box-demo", options: searchResults, getOptionLabel: getOptionLabel, groupBy: getGroupLabel, filterOptions: filterOptions, className: isPcSize ? classes.searchBar : classes.searchBarForSp, getOptionSelected: (option, value) => true, size: "small", loading: isLoadingSearchResults, inputValue: keyword, autoHighlight: true, openOnFocus: true, noOptionsText: "\u691C\u7D22\u7D50\u679C\u306F\u3042\u308A\u307E\u305B\u3093\u3002", loadingText: "\u8AAD\u8FBC\u4E2D...", onChange: handleChange, onInputChange: handleInputChange, onHighlightChange: handleHighlightChange, onClose: handleAutoCompleteClose, renderInput: (params) => (_jsx(TextField, { ...params, inputRef: searchInput, placeholder: "\u691C\u7D22(Ctrl+/)", variant: "outlined", style: { paddingLeft: '32px', fontSize: '14px', width: '100%' } })), renderOption: (option) => {
                    return (_jsxs("div", { className: classes.autoCompleteItem, children: [_jsx("div", { className: classes.autoCompleteItemLabel, children: getOptionLabel(option) }), _jsx(NavigateNextIcon, { className: classes.autoCompleteItemRightIcon })] }));
                } }), buildSubMenu()] }));
};
const SubMenu = (props) => {
    const { highlightItem, setHighlightItem } = props;
    const classes = useStyles();
    const handleClickMenuList = () => {
        setHighlightItem(null);
    };
    const buildSubMenuItemsForClient = () => {
        if (!highlightItem) {
            return [];
        }
        return Object.entries(ClientTabIndeies).map(([key, value], index) => (_jsx(MenuItem, { children: _jsxs(Link, { to: value.url(highlightItem.item.workspaceUid, highlightItem.item.clientId + ''), className: classes.subMenuItem, children: [_jsx(ListItemIcon, { style: { minWidth: '0px' }, children: value.icon }), _jsx("div", { className: classes.subMenuLabel, children: value.label })] }) }, key)));
    };
    const buildSubMenuItemsForShop = () => {
        if (!highlightItem) {
            return [];
        }
        return Object.entries(ShopTabIndeies).map(([key, value], index) => (_jsx(MenuItem, { children: _jsxs(Link, { to: value.url(highlightItem.item.workspaceUid, highlightItem.item.clientId + '', highlightItem.item.shopId + ''), className: classes.subMenuItem, children: [_jsx(ListItemIcon, { style: { minWidth: '0px' }, children: value.icon }), _jsx("div", { className: classes.subMenuLabel, children: value.label })] }) }, key)));
    };
    if (!highlightItem) {
        return null;
    }
    const sumMenuItems = highlightItem.item.type == 'client'
        ? buildSubMenuItemsForClient()
        : buildSubMenuItemsForShop();
    return (_jsx("div", { className: `MuiPaper-root MuiAutocomplete-paper MuiPaper-elevation1 MuiPaper-rounded ${classes.subMenu}`, style: {
            top: `${highlightItem.size.top - 18}px`,
            left: `${highlightItem.size.width + 32}px`,
        }, children: _jsx(MenuList, { onClick: handleClickMenuList, children: sumMenuItems }) }));
};
