import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { css, jsx } from '@emotion/react';
const styles = {
    chip: css({
        backgroundColor: 'rgba(146,146,157,0.6)',
        color: 'white',
        fontSize: 12,
        borderRadius: 12,
        height: 24,
        minWidth: 24,
        textAlign: 'center',
        lineHeight: 1,
        padding: 6,
        marginLeft: 5,
        display: 'block',
    }),
};
export default function NumberChip({ num, ...spanProps }) {
    return (_jsx("span", { css: styles.chip, style: num < 10 ? { borderRadius: '50%' } : {}, ...spanProps, children: num }));
}
