import { useEffect, useState } from 'react';
import { useWindowSize } from './use-window-size';
export const SM_BREAKPOINT = 599;
export const MD_BREAKPOINT = 899;
export const useSizeType = () => {
    const [sizeType, setSizeType] = useState('pc');
    const windowSize = useWindowSize();
    useEffect(() => {
        if (MD_BREAKPOINT < windowSize.width) {
            setSizeType('pc');
        }
        else if (SM_BREAKPOINT < windowSize.width &&
            windowSize.width <= MD_BREAKPOINT) {
            setSizeType('md');
        }
        else if (windowSize.width <= SM_BREAKPOINT) {
            setSizeType('sm');
        }
    }, [windowSize]);
    const isPcSize = sizeType === 'pc';
    const isTabletSize = sizeType === 'md';
    const isSpSize = sizeType === 'sm';
    return { sizeType, isPcSize, isTabletSize, isSpSize };
};
