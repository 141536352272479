import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined';
import EqualizerOutlined from '@material-ui/icons/EqualizerOutlined';
import GroupOutlined from '@material-ui/icons/GroupOutlined';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import LibraryAddCheckOutlined from '@material-ui/icons/LibraryAddCheckOutlined';
import SettingsOutlined from '@material-ui/icons/SettingsOutlined';
import React from 'react';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { canUseLocalScoreReportFeatures } from '../@interfaces/system/approval-beta-user';
import useClients from '../api/use-clients';
import { colors } from '../colors';
import { ROLE_ADMIN } from '../context/GlobalStore';
import useCurrentWorkspace from '../context/use-current-workspace';
import { MD_BREAKPOINT, SM_BREAKPOINT, useSizeType, } from '../hooks/use-size-type';
import NumberChip from './NumberChip';
const SIDEMENU_WIDTH = 280;
const nestedListItem = {
    padding: 0,
    paddingLeft: 15,
    fontSize: 12,
};
const listItemSquare = {
    width: 9,
    height: 9,
    backgroundColor: '#91919b',
    margin: 12,
};
const useStyles = makeStyles((theme) => ({
    drawer: {
        backgroundColor: 'white',
        width: SIDEMENU_WIDTH,
        borderRight: 'none',
    },
    drawerPaper: {
        width: SIDEMENU_WIDTH,
    },
    drawerClose: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: 65,
        height: '100vh',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        borderRight: 'none',
        transition: 'width .2s',
        '&:hover': {
            width: SIDEMENU_WIDTH,
            overflowX: 'auto',
        },
        zIndex: 1,
    },
    list: {
        paddingTop: 0,
    },
    nestedList: {
        padding: 0,
    },
    nestedListItem,
    nestedListItemActive: {
        ...nestedListItem,
        color: 'white',
        backgroundColor: colors.workspacePrimary,
        '&:hover': {
            backgroundColor: '#16292f',
        },
    },
    listItemSquare,
    listItemSquareActive: {
        ...listItemSquare,
        backgroundColor: 'white',
    },
    headerSpacer: {
        minHeight: 68,
    },
    clientNum: {
        backgroundColor: 'rgba(146,146,157,0.6)',
        color: 'white',
        fontSize: 12,
        borderRadius: 12,
        height: 24,
        minWidth: 24,
        textAlign: 'center',
        lineHeight: 1,
        padding: 6,
        marginLeft: 5,
    },
    tabsWrapper: {
        width: '100%',
        [`@media screen and (max-width: ${MD_BREAKPOINT}px)`]: {
            maxWidth: 'calc(100dvw - 54px)',
            margin: '0 auto',
        },
        [`@media screen and (max-width: ${SM_BREAKPOINT}px)`]: {
            maxWidth: 'calc(100dvw - 32px)',
            margin: '0 auto',
        },
    },
    tab: {
        textAlign: 'left',
        background: 'white',
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));
export default function WorkspaceSideMenu({ isClose, clients, }) {
    const classes = useStyles();
    const { isPcSize } = useSizeType();
    const { match } = useReactRouter();
    const { workspaceUid } = match.params;
    const workspace = useCurrentWorkspace(workspaceUid);
    if (!workspace) {
        return null;
    }
    return (_jsx(_Fragment, { children: isPcSize && (_jsxs(Drawer, { variant: "permanent", className: isClose ? classes.drawerClose : classes.drawer, anchor: "left", classes: {
                paper: isClose ? classes.drawerClose : classes.drawer,
            }, children: [_jsx(Toolbar, { className: classes.headerSpacer }), _jsx(WorkspaceSideMenuList, { workspace: workspace, clients: clients })] })) }));
}
export const WorkspaceSideMenuList = ({ workspace, clients, }) => {
    const classes = useStyles();
    const { history, location, match } = useReactRouter();
    const { workspaceUid } = match.params;
    if (!workspace) {
        return null;
    }
    if (!clients) {
        [clients] = useClients(workspaceUid);
    }
    const joinedClients = clients.filter((client) => client.joined);
    const isAdmin = workspace ? workspace.role === ROLE_ADMIN : false;
    return (_jsxs(List, { className: classes.list, children: [_jsx(SideMenuListItem, { icon: _jsx(AccountCircleOutlined, {}), text: `参加クライアント`, link: `/a/${workspaceUid}/joined-clients`, isActive: location.pathname.includes('joined-clients'), children: _jsx(NumberChip, { num: joinedClients.length || 0 }) }), isAdmin && (_jsxs(_Fragment, { children: [_jsx(SideMenuListItem, { icon: _jsx(GroupOutlined, {}), text: `全てのクライアント`, link: `/a/${workspaceUid}/all-clients`, isActive: location.pathname.includes('all-clients'), children: _jsx(NumberChip, { num: clients.length || 0 }) }), _jsx(SideMenuListItem, { icon: _jsx(EqualizerOutlined, {}), text: "\u6BD4\u8F03\u30EC\u30DD\u30FC\u30C8", link: `/a/${workspaceUid}/multi-shop-reports/datas`, isActive: location.pathname.includes('multi-shop-reports') }), location.pathname.includes('multi-shop-reports') && (_jsx(List, { className: classes.nestedList, children: [
                            {
                                text: '月別アンケート/クチコミ取得推移',
                                path: 'multi-shop-reports/datas',
                            },
                            {
                                text: '獲得クチコミ一覧',
                                path: 'multi-shop-reports/reviews',
                            },
                            {
                                text: '獲得アンケート一覧',
                                path: 'multi-shop-reports/enquete-responses',
                            },
                        ].map((object, index) => {
                            const isActive = location.pathname.includes(object.path);
                            return (_jsxs(ListItem, { className: isActive
                                    ? classes.nestedListItemActive
                                    : classes.nestedListItem, button: true, onClick: () => history.push(`/a/${workspaceUid}/${object.path}`), children: [_jsx("div", { className: isActive
                                            ? classes.listItemSquareActive
                                            : classes.listItemSquare }), object.text] }, index));
                        }) })), _jsx(SideMenuListItem, { icon: _jsx(LibraryAddCheckOutlined, {}), text: "\u65BD\u7B56\u30E1\u30E2", link: `/a/${workspaceUid}/tasks`, isActive: location.pathname.includes('tasks') })] })), canUseLocalScoreReportFeatures(workspaceUid) && (_jsx(SideMenuListItem, { icon: _jsx(ImageSearchIcon, {}), text: "\u30ED\u30FC\u30AB\u30EB\u30B9\u30B3\u30A2", link: `/a/${workspaceUid}/local-score-sales-reports`, isActive: location.pathname.includes('local-score-sales-reports') })), isAdmin && (_jsx(SideMenuListItem, { icon: _jsx(SettingsOutlined, {}), text: "\u8A2D\u5B9A", link: `/a/${workspaceUid}/setting`, isActive: location.pathname.includes('setting') }))] }));
};
const useStylesMenuList = makeStyles({
    activeListItem: {
        backgroundColor: colors.workspaceBackground,
    },
    activeIcon: {
        color: colors.workspacePrimary,
    },
    textPrimary: {
        fontSize: 14,
    },
    activeText: {
        color: colors.workspacePrimary,
    },
    activeBar: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 3,
        height: 45,
        backgroundColor: colors.workspacePrimary,
    },
});
const SideMenuListItem = ({ icon, text, isActive, link, children, }) => {
    const classes = useStylesMenuList();
    return (_jsx(Link, { to: link, style: {
            color: 'inherit',
            textDecoration: 'none',
        }, children: _jsxs(ListItem, { button: true, className: isActive ? classes.activeListItem : '', children: [isActive && _jsx("div", { className: classes.activeBar }), _jsx(ListItemIcon, { className: isActive ? classes.activeIcon : '', children: icon }), _jsx(ListItemText, { className: isActive ? classes.activeText : '', classes: {
                        primary: classes.textPrimary,
                    }, primary: text, style: {
                        flex: 'none',
                    } }), children !== null && children !== void 0 ? children : null] }) }));
};
