import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsx jsx */
/** @jsxFrag React.Fragment **/
import { css, jsx } from '@emotion/react';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Divider, Drawer, Grid, IconButton, Typography, } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useContext, useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose } from 'react-icons/io';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { signOut } from '../api/auth/cognito';
import { Store } from '../context/GlobalStore';
import useCurrentWorkspace from '../context/use-current-workspace';
import { ClientSideMenuList } from './ClientSideMenu';
import LinkRouter from './LinkRouter';
import { ShopSideMenuList } from './ShopSideMenu';
import { WorkspaceSideMenuList } from './WorkspaceSideMenu';
const styles = {
    drawerContent: css `
    width: 75dvw;
  `,
};
export const HamburgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { match } = useReactRouter();
    const { workspaceUid, clientId, shopId } = match.params;
    if (!workspaceUid)
        return _jsx(_Fragment, {});
    const workspace = useCurrentWorkspace(workspaceUid);
    const { globalState } = useContext(Store);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, { onClick: toggleMenu, children: _jsx(GiHamburgerMenu, { size: '40px' }) }), _jsx(Drawer, { open: isOpen, anchor: "right", onClose: toggleMenu, children: _jsxs(Box, { css: styles.drawerContent, children: [workspace && (_jsxs(_Fragment, { children: [_jsx(Box, { py: 1, pl: 2, pr: 1, children: _jsxs(Grid, { container: true, justifyContent: "space-between", alignItems: "center", children: [_jsx(Grid, { item: true, children: _jsx(Link, { to: `/a/${workspaceUid}/joined-clients`, style: {
                                                        textDecoration: 'none',
                                                    }, children: _jsx(Typography, { component: "h1", variant: "h6", noWrap: true, style: {
                                                            fontSize: '14px',
                                                            color: '#2A9FA1',
                                                        }, children: _jsx("span", { children: workspace.name }) }) }) }), _jsx(Grid, { item: true, children: _jsx(IconButton, { onClick: toggleMenu, children: _jsx(IoMdClose, { size: '40px' }) }) })] }) }), _jsx(Divider, {})] })), _jsx(SignedUser, {}), workspaceUid && (_jsx(_Fragment, { children: _jsx(HamburgerAccordion, { summary: "\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9\u30E1\u30CB\u30E5\u30FC", defaultExpanded: !clientId && !shopId, children: _jsx(WorkspaceSideMenuList, { workspace: workspace }) }) })), workspaceUid && clientId && (_jsx(_Fragment, { children: _jsx(HamburgerAccordion, { summary: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u30E1\u30CB\u30E5\u30FC", defaultExpanded: !shopId, children: _jsx(ClientSideMenuList, { workspace: workspace }) }) })), workspaceUid && clientId && shopId && (_jsx(_Fragment, { children: _jsx(HamburgerAccordion, { summary: "\u5E97\u8217\u30E1\u30CB\u30E5\u30FC", defaultExpanded: true, children: _jsx(ShopSideMenuList, { workspace: workspace, workspaceUid: workspaceUid, clientId: clientId, shopId: shopId }) }) })), globalState.session == undefined && (_jsx(LinkRouter, { to: "/signin", style: { color: '#fff' }, children: "\u30ED\u30B0\u30A4\u30F3" }))] }) })] }));
};
const SignedUser = () => {
    const { history } = useReactRouter();
    const { globalState, setGlobalState } = useContext(Store);
    const handleSignOut = () => {
        signOut();
        setGlobalState({ session: undefined, signedUser: undefined });
        history.push('/signin');
    };
    const Summary = () => {
        return (_jsx(_Fragment, { children: globalState.signedUser && (_jsxs(Grid, { container: true, justifyContent: "flex-start", alignItems: "center", children: [_jsx(Grid, { item: true, children: _jsx(Avatar, { style: {
                                width: '32px',
                                height: '32px',
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }, children: globalState.signedUser.account.name.substring(0, 1) }) }), _jsx(Grid, { item: true, style: {
                            fontSize: 14,
                            color: '#131523',
                            marginLeft: '8px',
                        }, children: globalState.signedUser.account.name })] })) }));
    };
    return (_jsx(_Fragment, { children: globalState.signedUser && (_jsx(HamburgerAccordion, { summary: _jsx(Summary, {}), defaultExpanded: false, children: _jsxs(Box, { children: [_jsx(Link, { to: "/profile", style: {
                            display: 'block',
                            width: '100%',
                            color: 'inherit',
                            textDecoration: 'none',
                            padding: '16px',
                        }, children: "\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB\u5909\u66F4" }), _jsx(Link, { to: '/favorite-list', style: {
                            display: 'block',
                            color: 'inherit',
                            textDecoration: 'none',
                            padding: '16px',
                        }, children: "\u304A\u6C17\u306B\u5165\u308A\u30EA\u30B9\u30C8" }), _jsx(Box, { onClick: handleSignOut, sx: { p: 2 }, children: "\u30ED\u30B0\u30A2\u30A6\u30C8" })] }) })) }));
};
const HamburgerAccordion = ({ summary, children, defaultExpanded, }) => {
    return (_jsxs(Accordion, { square: true, style: { boxShadow: 'none', borderBottom: '1px solid rgba(0,0,0,0.3)' }, defaultExpanded: defaultExpanded, children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), children: summary }), _jsx(AccordionDetails, { style: { padding: 0 }, children: _jsx(Box, { sx: { width: '100%' }, children: children }) })] }));
};
