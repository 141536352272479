import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';
export default function useWorkspaceSearch(keyword) {
    var _a;
    const { globalState, setGlobalState } = useContext(Store);
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const reload = () => {
        var _a, _b;
        if (!((_a = globalState.session) === null || _a === void 0 ? void 0 : _a.idToken) ||
            !keyword ||
            keyword.trim().length == 0) {
            setSearchResults([]);
            return;
        }
        const params = {
            keyword,
        };
        setLoading(true);
        axios
            .get(`${API_END_POINT}/app/search`, {
            params,
            headers: {
                Authorization: (_b = globalState.session) === null || _b === void 0 ? void 0 : _b.idToken,
            },
        })
            .then((response) => {
            setSearchResults(response.data.results);
        })
            .finally(() => {
            setLoading(false);
        });
    };
    useEffect(() => {
        reload();
    }, [keyword, (_a = globalState.session) === null || _a === void 0 ? void 0 : _a.idToken]);
    return {
        searchResults,
        isLoadingSearchResults: loading,
    };
}
