import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import { API_END_POINT } from './api';
import { Store } from '../context/GlobalStore';
const cache = {};
export default function useClients(workspaceUid) {
    var _a;
    const { globalState, setGlobalState } = useContext(Store);
    const [clients, setClients] = useState(cache[workspaceUid] || []);
    const [loading, setLoading] = useState(cache[workspaceUid] == undefined);
    useEffect(() => {
        var _a, _b;
        if (!workspaceUid || !((_a = globalState.session) === null || _a === void 0 ? void 0 : _a.idToken)) {
            setClients([]);
            return;
        }
        axios
            .get(`${API_END_POINT}/app/ws/${workspaceUid}/clients`, {
            headers: {
                Authorization: (_b = globalState.session) === null || _b === void 0 ? void 0 : _b.idToken,
            },
        })
            .then((response) => {
            setClients(response.data.clients);
            cache[workspaceUid] = response.data.clients;
        })
            .catch(() => {
            setClients([]);
        })
            .finally(() => {
            setLoading(false);
        });
    }, [workspaceUid, (_a = globalState.session) === null || _a === void 0 ? void 0 : _a.idToken]);
    return [clients, loading];
}
