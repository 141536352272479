import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Button, Container, Typography } from '@material-ui/core';
import React from 'react';
import { Head, Main, Root, TopAppBarSpacer } from '../../components/Shared';
export default function LazyLoadingErrorFallback() {
    const handleClickReload = () => {
        window.location.reload();
    };
    return (_jsxs(Root, { children: [_jsx(Head, { title: "error" }), _jsx(Main, { children: _jsxs(Container, { maxWidth: "lg", children: [_jsx(TopAppBarSpacer, {}), _jsx(Typography, { variant: "h5", style: {
                                marginBottom: 20,
                            }, children: "\u30ED\u30AB\u30AA\u30D7\u306E\u65B0\u3057\u3044\u30D0\u30FC\u30B8\u30E7\u30F3\u304C\u30EA\u30EA\u30FC\u30B9\u3055\u308C\u3066\u3044\u307E\u3059" }), _jsx(Typography, { style: {
                                marginBottom: 20,
                            }, children: "\u30D6\u30E9\u30A6\u30B6\u306E\u30EA\u30ED\u30FC\u30C9\u3092\u5B9F\u884C\u3057\u3066\u304F\u3060\u3055\u3044\u3002" }), _jsx(Button, { variant: "contained", color: "primary", onClick: handleClickReload, children: "\u30EA\u30ED\u30FC\u30C9\u3057\u3066\u65B0\u3057\u3044\u30D0\u30FC\u30B8\u30E7\u30F3\u3092\u4F7F\u7528\u3059\u308B" })] }) })] }));
}
