const availableWorkspaces = [
    'adlive',
    'all-e',
    'dmm_geo_boost',
    'sanctualead',
    'groobiz',
    'niwadani/all-clients',
    'kansaipado',
    'sensyuad',
    'pado',
    'atimes',
    'c-plus',
    'ns-pado',
    'kyoto-pd',
    'guchopa',
    'chuco-ms',
    'endo',
    'mirakul',
    'miraie-c',
    'atimes-recruit',
    'kizuna',
    'evokers',
    'bromo',
    'affluent',
    'k-create',
    'eby',
    'livingnetcom',
    'local-labo',
    'opt-kongo',
    'niwadani',
    'globalcast',
    'bc',
    'nissenmedix',
    'nicetown',
    'ietochihonpo',
    'iandf',
    'nicoichi',
    'companys',
    'growthlife',
    'infinity',
    'nishikou',
    'postivelinc',
    'p-partners',
    'insp',
    'taxi-media',
    'edito',
    'kurabiz',
    'creative-raja',
    'toprank',
    'shouwa',
    'nrc',
    'you-me',
    'zenken',
    'onecruise',
    'asakonet',
    'docodoor',
    'emio',
    'vision',
    'growthtrigger',
    'union',
    'exceedjapan',
    'globalcast',
    'zaidan',
    'webs',
    'answer',
    'ababai',
    'handpicks',
    'oscorporation',
    'okudegi',
    'wwwyomiuri-iscojp',
    'b-bsearch',
    'locas',
    'arigatou',
    'hhh',
    'signal',
    'expretech',
    'kitamura',
    'sofi',
    'samurai-ent',
    'jintonick',
    'zorse',
];
/**
 * クチコミクローリング機能が使用できるかどうかを判定する
 * @param adminWorkspaceUids アカウントが管理者として参加しているワークスペースのUIDリスト
 * @returns 使用できるならtrue
 */
export const canUseCrawlingReviewFeatures = (adminWorkspaceUids) => {
    return adminWorkspaceUids.some((uid) => availableWorkspaces.includes(uid));
};
export const canUseLocalScoreReportFeatures = (adminWorkspaceUid) => {
    /*
    今後セミナーをロカオプ並びに代理店さんもゲストのセミナーを
    開くと考えWSを追加することで目的別でローカルスコア専用WSを複数所有し、
    回せるように
    */
    //uidの始まり文字がlocal-scoreでその後文言が含まれている場合も使用可能
    const pattern = /^local-score.*/;
    return (availableWorkspaces.includes(adminWorkspaceUid) ||
        pattern.test(adminWorkspaceUid));
};
