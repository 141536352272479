import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
/** @jsx jsx */
/** @jsxFrag React.Fragment **/
import React, { useEffect, useState } from 'react';
import { jsx, css, keyframes } from '@emotion/react';
import { IconButton } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { smoothScroll } from '../utils/scroll-animation';
const fadeIn = keyframes `
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
const styles = {
    button: css({
        position: 'fixed',
        backgroundColor: '#2A9FA1',
        width: '48px',
        height: '48px',
        right: '16px',
        bottom: '100px',
        opacity: 0,
        animation: `${fadeIn} .3s forwards`,
        '&:hover': {
            backgroundColor: '#3BADAF',
        },
        ['@media print']: {
            display: 'none',
        },
    }),
    icon: css({
        color: '#FFFFFF',
        transform: 'rotate(90deg) translateX(4px)',
    }),
};
export default function ScrollTopButton(props) {
    const [isShow, setIsShow] = useState(false);
    const clickHandler = () => {
        smoothScroll();
    };
    const judgeIsShow = () => {
        const scrollY = window.pageYOffset;
        scrollY > 500 ? setIsShow(true) : setIsShow(false);
    };
    useEffect(() => {
        document.addEventListener('scroll', judgeIsShow);
        return () => document.removeEventListener('scroll', judgeIsShow);
    });
    return (_jsx(_Fragment, { children: isShow && (_jsx(IconButton, { css: styles.button, ...props, onClick: clickHandler, children: _jsx(ArrowBackIos, { css: styles.icon }) })) }));
}
