import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import HistoryIcon from '@material-ui/icons/History';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ListAltIcon from '@material-ui/icons/ListAlt';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import SendIcon from '@material-ui/icons/Send';
import SettingsIcon from '@material-ui/icons/Settings';
import StorefrontIcon from '@material-ui/icons/Storefront';
import TimelineIcon from '@material-ui/icons/Timeline';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import React, { memo, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import useShop from '../api/use-shop';
import { getShopIntegrations } from '../api/use-shop-integrations';
import { Store } from '../context/GlobalStore';
import useCurrentWorkspace from '../context/use-current-workspace';
import { MD_BREAKPOINT, SM_BREAKPOINT, useSizeType, } from '../hooks/use-size-type';
const SIDEMENU_WIDTH = 280;
const nestedListItem = {
    padding: 0,
    paddingLeft: 15,
    fontSize: 12,
};
const listItemSquare = {
    width: 9,
    height: 9,
    backgroundColor: '#91919b',
    margin: 12,
};
const useStyles = makeStyles((theme) => ({
    drawer: {
        backgroundColor: 'white',
        width: SIDEMENU_WIDTH,
        ['@media print']: {
            display: 'none',
        },
    },
    drawerPaper: {
        width: SIDEMENU_WIDTH,
        borderRight: 'none',
    },
    drawerClose: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: 65,
        height: '100vh',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        borderRight: 'none',
        transition: 'width .2s',
        '&:hover': {
            width: SIDEMENU_WIDTH,
        },
        zIndex: 11,
    },
    list: {
        paddingTop: 0,
    },
    nestedList: {
        padding: 0,
    },
    nestedListItem,
    nestedListItemActive: {
        ...nestedListItem,
        color: 'white',
        backgroundColor: '#2a9fa1',
        '&:hover': {
            backgroundColor: '#1a8f91',
        },
    },
    listItemSquare,
    listItemSquareActive: {
        ...listItemSquare,
        backgroundColor: 'white',
    },
    headerSpacer: {
        minHeight: 68,
    },
    tabsWrapper: {
        width: '100%',
        [`@media screen and (max-width: ${MD_BREAKPOINT}px)`]: {
            maxWidth: 'calc(100dvw - 54px)',
            margin: '0 auto',
        },
        [`@media screen and (max-width: ${SM_BREAKPOINT}px)`]: {
            maxWidth: 'calc(100dvw - 32px)',
            margin: '0 auto',
        },
    },
    tab: {
        textAlign: 'left',
        background: 'white',
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));
const localPostMenus = [
    {
        text: 'Googleビジネスプロフィール',
        path: 'local-posts',
    },
    {
        text: 'Instagram',
        path: 'instagram/medias',
    },
];
const matchLocalPostPath = (path) => {
    return localPostMenus.find((menu) => path.includes(menu.path));
};
export const TabIndeies = {
    basic: {
        url: (workspaceUid, clientId, shopId) => {
            return `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}`;
        },
        label: '基本情報',
        icon: _jsx(StorefrontIcon, {}),
    },
    'approval-requests': {
        url: (workspaceUid, clientId, shopId) => {
            return `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/approval-requests`;
        },
        label: '投稿予約・承認',
        icon: _jsx(SendIcon, {}),
    },
    'local-posts': {
        url: (workspaceUid, clientId, shopId) => {
            return `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/local-posts`;
        },
        label: '投稿一覧',
        icon: _jsx(DescriptionIcon, {}),
    },
    reviews: {
        url: (workspaceUid, clientId, shopId) => {
            return `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/reviews`;
        },
        label: 'クチコミ',
        icon: _jsx(RecordVoiceOverIcon, {}),
    },
    enquetes: {
        url: (workspaceUid, clientId, shopId) => {
            return `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/enquetes`;
        },
        label: 'アンケート',
        icon: _jsx(ListAltIcon, {}),
    },
    sites: {
        url: (workspaceUid, clientId, shopId) => {
            return `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/sites`;
        },
        label: 'サイト',
        icon: _jsx(WebAssetIcon, {}),
    },
    report: {
        url: (workspaceUid, clientId, shopId) => {
            return `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/reports`;
        },
        label: 'レポート',
        icon: _jsx(LibraryBooksIcon, {}),
    },
    insight: {
        url: (workspaceUid, clientId, shopId) => {
            return `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/insight`;
        },
        label: 'インサイト',
        icon: _jsx(TimelineIcon, {}),
    },
    tasks: {
        url: (workspaceUid, clientId, shopId) => {
            return `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/tasks`;
        },
        label: '施策メモ',
        icon: _jsx(LibraryAddCheckIcon, {}),
    },
    activities: {
        url: (workspaceUid, clientId, shopId) => {
            return `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/activities`;
        },
        label: '変更履歴',
        icon: _jsx(HistoryIcon, {}),
    },
    settings: {
        url: (workspaceUid, clientId, shopId) => {
            return `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/competitors`;
        },
        label: '設定',
        icon: _jsx(SettingsIcon, {}),
    },
};
export const ShopSideMenu = memo(function ShopSideMenu({ shop, showPage, isClose }) {
    const classes = useStyles();
    const { match } = useReactRouter();
    const { workspaceUid, clientId, shopId } = match.params;
    const { isPcSize } = useSizeType();
    const workspace = useCurrentWorkspace(workspaceUid);
    if (!workspace) {
        return null;
    }
    return (_jsx(_Fragment, { children: isPcSize && (_jsxs(Drawer, { variant: "permanent", className: isClose ? classes.drawerClose : classes.drawer, anchor: "left", classes: {
                paper: isClose ? classes.drawerClose : classes.drawerPaper,
            }, children: [_jsx(Toolbar, { className: classes.headerSpacer }), _jsx(ShopSideMenuList, { workspace: workspace, workspaceUid: workspaceUid, clientId: clientId, shopId: shopId })] })) }));
});
export const ShopSideMenuList = ({ workspace, workspaceUid, clientId, shopId, }) => {
    const classes = useStyles();
    const { globalState } = useContext(Store);
    const { history } = useReactRouter();
    const [connectedInstagram, setConnectedInstagram] = useState(false);
    useEffect(() => {
        var _a;
        if (!((_a = globalState.session) === null || _a === void 0 ? void 0 : _a.idToken) ||
            !matchLocalPostPath(window.location.href)) {
            return;
        }
        getShopIntegrations(globalState.session.idToken, clientId, shopId).then((response) => {
            var _a, _b;
            setConnectedInstagram(response.data.instagram.instagram !== undefined ||
                (((_a = response.data.instagram.setting) === null || _a === void 0 ? void 0 : _a.integrationType) === 'simple' &&
                    ((_b = response.data.instagram.setting) === null || _b === void 0 ? void 0 : _b.username) != undefined));
        });
    }, [window.location.href]);
    if (!workspace) {
        return null;
    }
    const [shop] = useShop(clientId, shopId);
    if (!shop) {
        return null;
    }
    const isActiveFunc = (pageName) => {
        return location.pathname.includes(pageName);
    };
    return (_jsxs(List, { className: classes.list, children: [_jsx(SideMenuListItem, { icon: _jsx(StorefrontIcon, {}), text: "\u57FA\u672C\u60C5\u5831", link: `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}`, isActive: isActiveFunc('basic') }), _jsx(SideMenuListItem, { icon: _jsx(SendIcon, {}), text: "\u6295\u7A3F\u4E88\u7D04\u30FB\u627F\u8A8D", link: `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/approval-requests`, isActive: isActiveFunc('approval-requests') }), _jsx(SideMenuListItem, { icon: _jsx(DescriptionIcon, {}), text: "\u6295\u7A3F\u4E00\u89A7", link: `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/local-posts`, isActive: isActiveFunc('local-posts') }), matchLocalPostPath(window.location.href) && connectedInstagram && (_jsxs(List, { className: classes.nestedList, children: [localPostMenus.map((object, index) => {
                        const isActive = location.pathname.includes(object.path);
                        return (_jsxs(ListItem, { className: isActive
                                ? classes.nestedListItemActive
                                : classes.nestedListItem, button: true, onClick: () => history.push(`/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/${object.path}`), children: [_jsx("div", { className: isActive
                                        ? classes.listItemSquareActive
                                        : classes.listItemSquare }), object.text] }, index));
                    }), shopId === '8890' && (_jsxs(ListItem, { className: classes.nestedListItem, button: true, onClick: () => history.push(`/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/instagram/top-medias`), children: [_jsx("div", { className: classes.listItemSquare }), "Instagram Top"] }))] })), _jsx(SideMenuListItem, { icon: _jsx(RecordVoiceOverIcon, {}), text: "\u30AF\u30C1\u30B3\u30DF", link: `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/reviews`, isActive: isActiveFunc('reviews') }), _jsx(SideMenuListItem, { icon: _jsx(ListAltIcon, {}), text: "\u30A2\u30F3\u30B1\u30FC\u30C8", link: `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/enquetes`, isActive: isActiveFunc('enquetes') }), _jsx(SideMenuListItem, { icon: _jsx(WebAssetIcon, {}), text: "\u30B5\u30A4\u30C8", link: `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/sites`, isActive: isActiveFunc('sites') }), _jsx(SideMenuListItem, { icon: _jsx(LibraryBooksIcon, {}), text: "\u30EC\u30DD\u30FC\u30C8", link: `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/reports`, isActive: isActiveFunc('report') }), (shop === null || shop === void 0 ? void 0 : shop.location) && (_jsx(SideMenuListItem, { icon: _jsx(TimelineIcon, {}), text: "\u30A4\u30F3\u30B5\u30A4\u30C8", link: `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/insight`, isActive: isActiveFunc('insight') })), _jsx(SideMenuListItem, { icon: _jsx(LibraryAddCheckIcon, {}), text: "\u65BD\u7B56\u30E1\u30E2", link: `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/tasks`, isActive: isActiveFunc('tasks') }), _jsx(SideMenuListItem, { icon: _jsx(HistoryIcon, {}), text: "\u5909\u66F4\u5C65\u6B74", link: `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/activities`, isActive: isActiveFunc('activities') }), _jsx(SideMenuListItem, { icon: _jsx(SettingsIcon, {}), text: "\u8A2D\u5B9A", link: `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/competitors`, isActive: isActiveFunc('settings') })] }));
};
const useStylesMenuList = makeStyles({
    activeListItem: {
        backgroundColor: '#f3fafa',
    },
    activeIcon: {
        color: '#2a9fa1',
    },
    textPrimary: {
        fontSize: 14,
    },
    activeText: {
        color: '#2a9fa1',
    },
    activeBar: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 3,
        height: 45,
        backgroundColor: '#2a9fa1',
    },
});
const SideMenuListItem = ({ icon, text, isActive, link, }) => {
    const classes = useStylesMenuList();
    return (_jsx(Link, { to: link, style: {
            color: 'inherit',
            fontSize: 'inherit',
            textDecoration: 'none',
        }, children: _jsxs(ListItem, { button: true, className: isActive ? classes.activeListItem : '', children: [isActive && _jsx("div", { className: classes.activeBar }), _jsx(ListItemIcon, { className: isActive ? classes.activeIcon : '', children: icon }), _jsx(ListItemText, { className: isActive ? classes.activeText : '', classes: {
                        primary: classes.textPrimary,
                    }, style: {
                        fontSize: '14px',
                    }, primary: text })] }) }));
};
