import { useCallback, useEffect, useState } from 'react';
export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0,
    });
    useEffect(() => {
        setWindowSize({
            width: document.body.clientWidth,
            height: document.body.clientHeight,
        });
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const handleResize = useCallback(() => {
        window.addEventListener('resize', () => {
            setWindowSize({
                width: document.body.clientWidth,
                height: document.body.clientHeight,
            });
        });
    }, [windowSize.width, windowSize.height]);
    return windowSize;
};
