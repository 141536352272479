import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import EqualizerOutlined from '@material-ui/icons/EqualizerOutlined';
import SettingsOutlined from '@material-ui/icons/SettingsOutlined';
import StorefrontRoundedIcon from '@material-ui/icons/StorefrontRounded';
import React from 'react';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import useShops from '../api/use-shops';
import { ROLE_ADMIN } from '../context/GlobalStore';
import useCurrentWorkspace from '../context/use-current-workspace';
import { MD_BREAKPOINT, SM_BREAKPOINT, useSizeType, } from '../hooks/use-size-type';
import NumberChip from './NumberChip';
const SIDEMENU_WIDTH = 280;
const nestedListItem = {
    padding: 0,
    paddingLeft: 15,
    fontSize: 12,
};
const listItemSquare = {
    width: 9,
    height: 9,
    backgroundColor: '#91919b',
    margin: 12,
};
const useStyles = makeStyles((theme) => ({
    drawer: {
        backgroundColor: 'white',
        width: SIDEMENU_WIDTH,
        borderRight: 'none',
    },
    drawerPaper: {
        width: SIDEMENU_WIDTH,
    },
    drawerClose: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: 65,
        height: '100vh',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        borderRight: 'none',
        transition: 'width .2s',
        zIndex: 11,
        '&:hover': {
            width: SIDEMENU_WIDTH,
            overflowX: 'auto',
        },
    },
    list: {
        paddingTop: 0,
    },
    nestedList: {
        padding: 0,
    },
    nestedListItem,
    nestedListItemActive: {
        ...nestedListItem,
        color: 'white',
        backgroundColor: '#2a9fa1',
        '&:hover': {
            backgroundColor: '#1a8f91',
        },
    },
    listItemSquare,
    listItemSquareActive: {
        ...listItemSquare,
        backgroundColor: 'white',
    },
    headerSpacer: {
        minHeight: 68,
    },
    clientNum: {
        backgroundColor: 'rgba(146,146,157,0.6)',
        color: 'white',
        fontSize: 12,
        borderRadius: 12,
        textAlign: 'center',
        height: 24,
        minWidth: 24,
        lineHeight: 1,
        padding: 6,
        marginLeft: 5,
    },
    tabsWrapper: {
        width: '100%',
        [`@media screen and (max-width: ${MD_BREAKPOINT}px)`]: {
            maxWidth: 'calc(100dvw - 54px)',
            margin: '0 auto',
        },
        [`@media screen and (max-width: ${SM_BREAKPOINT}px)`]: {
            maxWidth: 'calc(100dvw - 32px)',
            margin: '0 auto',
        },
    },
    tab: {
        textAlign: 'left',
        background: 'white',
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));
export const TabIndeies = {
    shops: {
        url: (workspaceUid, clientId) => {
            return `/a/${workspaceUid}/clients/${clientId}`;
        },
        label: '店舗一覧',
        icon: _jsx(StorefrontRoundedIcon, {}),
    },
    'bulk-local-post': {
        url: (workspaceUid, clientId) => {
            return `/a/${workspaceUid}/clients/${clientId}/bulk-local-post`;
        },
        label: '一括投稿',
        icon: _jsx(AllInboxIcon, {}),
    },
    'multi-shop-reports': {
        url: (workspaceUid, clientId) => {
            return `/a/${workspaceUid}/clients/${clientId}/multi-shop-reports/datas`;
        },
        label: '比較レポート',
        icon: _jsx(EqualizerOutlined, {}),
    },
    setting: {
        url: (workspaceUid, clientId) => {
            return `/a/${workspaceUid}/clients/${clientId}/setting`;
        },
        label: '設定',
        icon: _jsx(SettingsOutlined, {}),
    },
};
const pageList = [
    {
        text: '月別アンケート/クチコミ取得推移',
        path: 'datas',
    },
    {
        text: '獲得クチコミ一覧',
        path: 'reviews',
    },
    {
        text: '獲得アンケート一覧',
        path: 'enquete-responses',
    },
    {
        text: '複数店舗サマリー',
        path: 'summary',
    },
];
export default function ClientSideMenu({ isClose, shops, }) {
    const classes = useStyles();
    const { match } = useReactRouter();
    const { workspaceUid } = match.params;
    const { isPcSize } = useSizeType();
    const workspace = useCurrentWorkspace(workspaceUid);
    if (!workspace) {
        return null;
    }
    if (!pageList.find((page) => 'データダウンロード'.includes(page.text))) {
        pageList.push({
            text: 'データダウンロード',
            path: 'csv-downloads',
        });
    }
    return (_jsx(_Fragment, { children: isPcSize && (_jsxs(Drawer, { variant: "permanent", className: isClose ? classes.drawerClose : classes.drawer, anchor: "left", classes: {
                paper: isClose ? classes.drawerClose : classes.drawer,
            }, children: [_jsx(Toolbar, { className: classes.headerSpacer }), _jsx(ClientSideMenuList, { workspace: workspace, shops: shops })] })) }));
}
export const ClientSideMenuList = ({ workspace, shops, }) => {
    const classes = useStyles();
    const { location, match } = useReactRouter();
    const { workspaceUid, clientId } = match.params;
    if (!workspace) {
        return null;
    }
    if (!shops) {
        shops = useShops(clientId).shops;
    }
    const isAdmin = workspace ? workspace.role === ROLE_ADMIN : false;
    return (_jsxs(List, { className: classes.list, children: [_jsx(SideMenuListItem, { icon: _jsx(StorefrontRoundedIcon, {}), text: `店舗一覧`, link: `/a/${workspaceUid}/clients/${clientId}`, isActive: !location.pathname.includes('multi-shop-reports') &&
                    !location.pathname.includes('setting') &&
                    !location.pathname.includes('bulk-local-post'), children: _jsx(NumberChip, { num: shops.length }) }), _jsx(SideMenuListItem, { icon: _jsx(AllInboxIcon, {}), text: `一括投稿`, link: `/a/${workspaceUid}/clients/${clientId}/bulk-local-post`, isActive: location.pathname.includes('bulk-local-post') }), _jsx(SideMenuListItem, { icon: _jsx(EqualizerOutlined, {}), text: "\u6BD4\u8F03\u30EC\u30DD\u30FC\u30C8", link: `/a/${workspaceUid}/clients/${clientId}/multi-shop-reports/datas`, isActive: location.pathname.includes('multi-shop-reports') }), location.pathname.includes('multi-shop-reports') && (_jsx(List, { className: classes.nestedList, children: pageList.map((object, index) => {
                    const isActive = location.pathname.includes(object.path);
                    return (_jsx(Link, { to: `/a/${workspaceUid}/clients/${clientId}/multi-shop-reports/${object.path}`, style: {
                            color: 'inherit',
                            textDecoration: 'none',
                        }, children: _jsxs(ListItem, { className: isActive
                                ? classes.nestedListItemActive
                                : classes.nestedListItem, button: true, children: [_jsx("div", { className: isActive
                                        ? classes.listItemSquareActive
                                        : classes.listItemSquare }), object.text] }) }, index));
                }) })), isAdmin && (_jsx(_Fragment, { children: _jsx(SideMenuListItem, { icon: _jsx(SettingsOutlined, {}), text: "\u8A2D\u5B9A", link: `/a/${workspaceUid}/clients/${clientId}/setting`, isActive: location.pathname.includes('setting') }) }))] }));
};
const useStylesMenuList = makeStyles({
    activeListItem: {
        backgroundColor: '#f3fafa',
    },
    activeIcon: {
        color: '#2a9fa1',
    },
    textPrimary: {
        fontSize: 14,
    },
    activeText: {
        color: '#2a9fa1',
    },
    activeBar: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 3,
        height: 45,
        backgroundColor: '#2a9fa1',
    },
});
const SideMenuListItem = ({ icon, text, isActive, link, children, }) => {
    const classes = useStylesMenuList();
    return (_jsx(Link, { to: link, style: {
            color: 'inherit',
            textDecoration: 'none',
            fontSize: '14px',
        }, children: _jsxs(ListItem, { button: true, className: isActive ? classes.activeListItem : '', children: [isActive && _jsx("div", { className: classes.activeBar }), _jsx(ListItemIcon, { className: isActive ? classes.activeIcon : '', children: icon }), _jsx(ListItemText, { className: isActive ? classes.activeText : '', classes: {
                        primary: classes.textPrimary,
                    }, primary: text, style: {
                        flex: 'none',
                    } }), children !== null && children !== void 0 ? children : null] }) }));
};
