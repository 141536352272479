import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import App from './App';
import * as serviceWorker from './serviceWorker';
ReactDOM.render(_jsxs(HelmetProvider, { children: [location.hostname !== 'localhost' && (_jsxs(Helmet, { children: [_jsx("script", { async: true, src: "https://www.googletagmanager.com/gtag/js?id=GTM-K2DXTTD" }), _jsx("script", { children: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'GTM-K2DXTTD');
    ` })] })), _jsx(App, {})] }), document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
