import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
export default function Spinner(props) {
    if (!props.loading) {
        return null;
    }
    let style = {};
    if (props.size) {
        style = { width: props.size, height: props.size };
    }
    return _jsx(CircularProgress, { style: style });
}
