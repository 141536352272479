const getFrontendApiEndPoint = () => {
    var _a;
    return (_a = process.env.REACT_APP_API_END_POINT) !== null && _a !== void 0 ? _a : '';
};
const getFrontendAccountApiEndPoint = () => {
    var _a;
    return (_a = process.env.REACT_APP_ACCOUNT_API_END_POINT) !== null && _a !== void 0 ? _a : '';
};
const CLIENT_ID = '123454770708-g3hrv0f5mdj9q6dj753l2ujs3as9omgd.apps.googleusercontent.com';
const SCOPES = [
    'https://www.googleapis.com/auth/business.manage',
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
].join(' ');
export const API_END_POINT = getFrontendApiEndPoint();
export const ACCOUNT_API_END_POINT = getFrontendAccountApiEndPoint();
export const GMB_API_END_POINT = 'https://mybusiness.googleapis.com/v4';
export const GMB_ACCOUNT_MAGAGEMENT_API_END_POINT = 'https://mybusinessaccountmanagement.googleapis.com/v1';
export const GMB_INFORMATION_API_END_POINT = 'https://mybusinessbusinessinformation.googleapis.com/v1';
// see https://console.cloud.google.com/apis/credentials?project=fujisan-meo-maps-sdk&supportedpurview=project
const GOOGLE_MAP_JS_SDK_API_KEY = 'AIzaSyDTphZ8THxS0RZB7eL_QQMXEhWeS2oAVog';
export const GOOGLE_MAP_API_SOURCE_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_JS_SDK_API_KEY}&amp;libraries=places&amp;callback=initMap`;
export const getGoogleAuthorizeUrl = (callbackPath, workspaceUid) => {
    const state = JSON.stringify({
        callback: callbackPath,
        workspaceUid,
    });
    const url = new URL('https://accounts.google.com/o/oauth2/auth');
    const params = url.searchParams;
    params.append('response_type', 'code');
    params.append('client_id', CLIENT_ID);
    params.append('redirect_uri', window.location.origin + `/gaccounts/oauth/callback`);
    params.append('scope', SCOPES);
    params.append('access_type', 'offline');
    params.append('prompt', 'consent');
    params.append('state', state);
    return url.href;
};
