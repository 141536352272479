import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import React from 'react';
const NODATA = -1;
export function displayNo(no) {
    if (no == undefined) {
        return _jsx("span", { style: { color: '#red' }, children: "\u570F\u5916" });
    }
    else if (no == -1) {
        return _jsx("span", { style: { color: '#999' }, children: "\u30C7\u30FC\u30BF\u7121\u3057" });
    }
    else {
        return _jsx("span", { children: no });
    }
}
export function getDiff(keyword) {
    if (!keyword.no ||
        !keyword.noLastweek ||
        keyword.no == NODATA ||
        keyword.noLastweek == NODATA) {
        return null;
    }
    if (keyword.no == keyword.noLastweek) {
        return null;
    }
    else if (keyword.no < keyword.noLastweek) {
        return (_jsxs("span", { style: { color: 'green', fontWeight: 'bold', paddingLeft: '5px' }, children: ["\u2191 ", keyword.noLastweek - keyword.no] }));
    }
    else {
        return (_jsxs("span", { style: { color: 'red', fontWeight: 'bold', paddingLeft: '5px' }, children: ["\u2193 ", keyword.no - keyword.noLastweek] }));
    }
}
export const handleOpenUrl = (url) => {
    return () => {
        window.open(url, '_blank', '');
    };
};
