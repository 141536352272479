const BASE_URL = 'https://support.locaop.jp/hc/ja';
export const helps = {
    shop: {
        basic: 'sections/4415162989209',
        task: 'sections/4415762125465',
        enquete: 'sections/4416640716441',
        approvalRequest: 'sections/4417210905241',
        insight: 'sections/4416537498137',
        site: 'sections/4419364956057',
        review: 'sections/4415141417881',
        report: 'sections/4415284018585',
        activity: 'sections/4415283948697',
        keyword: 'articles/4415162997785',
        keywordEdit: 'articles/4415763128729',
        setting: {
            toc: 'sections/4416639692057',
            approvalRequest: 'articles/4416653842585',
            competitor: 'articles/4416673515033',
            gbp: 'articles/4417317237529',
            report: 'articles/4415975192601',
            basic: 'articles/4417834380313',
        },
        location: 'articles/4417317237529',
        integrations: 'sections/8858551880857',
    },
};
export const openHelpPage = (helpId) => {
    const url = helpId ? `${BASE_URL}/${helpId}` : BASE_URL;
    window.open(url, '_blank');
};
