import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';
const cache = {};
export const clearShopsCache = (clientId) => {
    delete cache[clientId];
};
export const getShops = (clientId, idToken) => {
    return axios.get(`${API_END_POINT}/app/clients/${clientId}/shops`, {
        headers: {
            Authorization: idToken,
        },
    });
};
export default function useShops(clientId) {
    var _a;
    const { globalState } = useContext(Store);
    const [shops, setShops] = useState(clientId ? cache[clientId] || [] : []);
    const [loading, setLoading] = useState(clientId && cache[clientId] == undefined);
    const reload = () => {
        var _a, _b;
        if (!((_a = globalState.session) === null || _a === void 0 ? void 0 : _a.idToken) || !clientId) {
            setShops([]);
            return;
        }
        getShops(clientId, (_b = globalState.session) === null || _b === void 0 ? void 0 : _b.idToken)
            .then((response) => {
            setShops(response.data.shops);
            cache[clientId] = response.data.shops;
        })
            .finally(() => {
            setLoading(false);
        });
    };
    useEffect(() => {
        reload();
    }, [clientId, (_a = globalState.session) === null || _a === void 0 ? void 0 : _a.idToken]);
    return {
        shops,
        isLoadingShops: loading,
        reloadShops: reload,
    };
}
