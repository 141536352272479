import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';
export const getShopIntegrations = (idToken, clientId, shopId) => {
    return axios.get(`${API_END_POINT}/app/clients/${clientId}/shops/${shopId}/integrations`, {
        headers: {
            Authorization: idToken,
        },
    });
};
export default function useShopIntegrations(clientId, shopId) {
    var _a;
    const { globalState, setGlobalState } = useContext(Store);
    const [shopIntegrations, setShopIntegrations] = useState();
    const [loading, setLoading] = useState(false);
    const reload = () => {
        var _a;
        if (!shopId || !((_a = globalState.session) === null || _a === void 0 ? void 0 : _a.idToken)) {
            return;
        }
        getShopIntegrations(globalState.session.idToken, clientId, shopId)
            .then((response) => {
            setShopIntegrations(response.data);
        })
            .finally(() => {
            setLoading(false);
        });
    };
    useEffect(() => {
        reload();
    }, [clientId, shopId, (_a = globalState.session) === null || _a === void 0 ? void 0 : _a.idToken]);
    return {
        shopIntegrations,
        isLoadingShopIntegrations: loading,
        reloadShopIntegrations: reload,
    };
}
