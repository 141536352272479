var _a, _b;
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, } from 'amazon-cognito-identity-js';
// ユーザープールの設定
const poolData = {
    UserPoolId: (_a = process.env.REACT_APP_COGNITO_USER_POOL_ID) !== null && _a !== void 0 ? _a : '',
    ClientId: (_b = process.env.REACT_APP_COGNITO_CLIENT_ID) !== null && _b !== void 0 ? _b : '',
};
const userPool = new CognitoUserPool(poolData);
export const signup = (email, name, password, token, callback) => {
    const nameValue = {
        Name: 'name',
        Value: name,
    };
    const nameAttr = new CognitoUserAttribute(nameValue);
    const attrs = [];
    attrs.push(nameAttr);
    if (token) {
        const tokenValue = {
            Name: 'custom:token',
            Value: token,
        };
        const tokenAttr = new CognitoUserAttribute(tokenValue);
        attrs.push(tokenAttr);
    }
    userPool.signUp(email, password, attrs, [], callback);
};
export const activate = (email, code, callback) => {
    const cognitoUser = newCognitoUser(email);
    cognitoUser.confirmRegistration(code, true, callback);
};
export const newCognitoUser = (email) => {
    const userData = {
        Username: email,
        Pool: userPool,
    };
    return new CognitoUser(userData);
};
export const signin = (email, password, callback) => {
    const authenticationData = {
        Username: email,
        Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const cognitoUser = newCognitoUser(email);
    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
            const idToken = result.getIdToken().getJwtToken();
            const accessToken = result.getAccessToken().getJwtToken();
            const refreshToken = result.getRefreshToken().getToken();
            callback.onSuccess(result);
        },
        onFailure: function (err) {
            callback.onFailure(err);
        },
    });
};
export const getCurrentUser = () => userPool.getCurrentUser();
export const getSession = (callback) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
            callback(err, session);
        });
    }
    else {
        const err = { name: 'NOT_LOGGED_IN', message: 'ログインしていません' };
        callback(err, undefined);
    }
};
export const signOut = () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
        cognitoUser.signOut();
    }
};
export const changePassword = async (oldPassword, newPassword) => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
        throw new Error('ログインしていません');
    }
    return new Promise((resolve, reject) => {
        cognitoUser.getSession((sessionError) => {
            if (sessionError) {
                reject(sessionError);
                return;
            }
            cognitoUser.changePassword(oldPassword, newPassword, (err) => {
                if (err) {
                    reject(err);
                }
                else {
                    resolve();
                }
            });
        });
    });
};
