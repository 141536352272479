import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import styled from '@emotion/styled';
import { Backdrop, Box, Button, CircularProgress, Divider, Grid, Paper, } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import React, { memo, useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { signOut } from '../api/auth/cognito';
import { colors } from '../colors';
import { Store } from '../context/GlobalStore';
import useCurrentWorkspace from '../context/use-current-workspace';
import { useSizeType } from '../hooks/use-size-type';
import { openHelpPage } from '../utils/helps';
import { handleOpenUrl } from '../utils/view-helper';
import { HamburgerMenu } from './HamburgerMenu';
import LinkButton from './LinkButton';
import LinkRouter from './LinkRouter';
import { SearchBar } from './SearchBar';
import { useStyles as commonUseStyles } from './Styles';
const drawerWidth = 280;
const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    searchBarContainer: {
        padding: '0px 20px',
        maxWidth: '700px',
    },
    title: {
        marginLeft: '30px',
        color: '#2A9FA1',
        lineHeight: '100%',
        fontSize: '18px',
        '&:hover': {
            color: '#4AAFC1',
        },
        cursor: 'pointer',
    },
    logo: {
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.7,
        },
    },
    logoText: {
        fontSize: '14px',
        fontWeight: 'bold',
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.7,
        },
    },
    root: {
        display: 'flex',
        marginTop: 0,
        minHeight: '100dvh',
    },
    rootForSp: {
        display: 'block',
        marginTop: '68px',
        miHeight: 'calc(100dvh - 68px)',
        maxWidth: '100dvw',
        overflowX: 'scroll',
        ['@media print']: {
            display: 'flex',
            marginTop: 0,
            minHeight: '100dvh',
            maxWidth: 'none',
            overflowX: 'visible',
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ['@media print']: {
            display: 'none',
        },
    },
    appBarForSp: {
        width: '100dvw',
        left: 0,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ['@media print']: {
            display: 'none',
        },
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        ['@media print']: {
            display: 'none',
        },
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    userIconButton: {
        padding: '12px 0',
        marginLeft: '16px',
        '&:hover': {
            background: 'transparent',
            '& .name': {
                textDecoration: 'underline',
            },
        },
    },
    avatar: {
        width: 42,
        height: 42,
        fontSize: 16,
        fontWeight: 'bold',
        color: '#FFFFFF',
    },
    userIconButtonForSp: {
        padding: '0',
        marginLeft: '8px',
        '&:hover': {
            background: 'transparent',
            '& .name': {
                textDecoration: 'underline',
            },
        },
    },
    avatarForSp: {
        width: 28,
        height: 28,
        fontSize: 14,
        fontWeight: 'bold',
        color: '#FFFFFF',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    backdrop: {
        zIndex: 99999,
        color: '#fff',
    },
}));
export const Root = ({ children, style, css, }) => {
    const { isPcSize } = useSizeType();
    const classes = useStyles();
    return (_jsx(Box, { className: isPcSize ? classes.root : classes.rootForSp, style: style, children: children }));
};
const StyledWorkspaceRoot = styled.div `
  ${(props) => `display: ${props.isSp ? 'block' : 'flex'}`};
  ${(props) => `margin-top: ${props.isSp ? '68px' : '0'}`};
  ${(props) => `min-height: ${props.isSp ? 'calc(100dvh - 68px)' : '100dvh'}`};
  background-color: ${colors.workspaceBackground};
`;
export const WorkspaceRoot = ({ children }) => {
    const { isSpSize, isTabletSize } = useSizeType();
    return (_jsx(StyledWorkspaceRoot, { isSp: isSpSize || isTabletSize, children: children }));
};
export const Main = styled.div `
  flex-grow: 1;
  padding-bottom: 180px;
`;
export const TopAppBar = memo(function TopAppBar({ helpId }) {
    const classes = useStyles();
    const { history, location, match } = useReactRouter();
    const { workspaceUid } = match.params;
    const workspace = useCurrentWorkspace(workspaceUid);
    const { globalState, setGlobalState } = useContext(Store);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const { isPcSize } = useSizeType();
    const [isShowSearchElemForSp, setIsShowSearchElemForSp] = useState(false);
    const logoPath = useLogoPath(workspaceUid);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSignout = () => {
        signOut();
        setGlobalState({ session: undefined, signedUser: undefined });
        history.push('/signin');
    };
    const handleClickSearch = () => {
        setIsShowSearchElemForSp(!isShowSearchElemForSp);
    };
    const buildSearchBar = () => {
        const pathsWithSearchBar = ['/', '/favorite-list', '/profile'];
        if (workspaceUid || pathsWithSearchBar.includes(location.pathname)) {
            return (_jsx("div", { className: classes.searchBarContainer, children: _jsx(SearchBar, {}) }));
        }
        else {
            return _jsx("div", { className: classes.searchBarContainer });
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(AppBar, { position: "fixed", className: isPcSize ? clsx(classes.appBar) : clsx(classes.appBarForSp), style: {
                background: '#FFFFFF',
                boxShadow: '0 1px 4px rgba(21, 34, 50, .08)',
            }, children: [_jsx(Toolbar, { style: {
                        minHeight: '68px',
                        padding: isPcSize ? '0 30px 0 20px' : '0 8px 0 16px',
                    }, children: _jsxs(Grid, { container: true, justifyContent: "space-between", alignItems: "center", children: [_jsxs(Grid, { item: true, container: true, justifyContent: "flex-start", alignItems: "center", style: { width: 'fit-content', flex: 1 }, children: [_jsx(Link, { to: '/', children: _jsx("img", { src: logoPath, alt: "", height: isPcSize ? '24' : '14', style: {
                                                verticalAlign: 'middle',
                                                marginRight: '2px',
                                                marginBottom: '2px',
                                            }, className: classes.logo }) }), isPcSize && (_jsxs(_Fragment, { children: [_jsx(Link, { to: `/a/${workspaceUid}/joined-clients`, style: {
                                                    textDecoration: 'none',
                                                }, children: _jsx(Typography, { component: "h1", variant: "h6", noWrap: true, className: classes.title, style: {
                                                        marginLeft: isPcSize ? '30px' : '16px',
                                                        fontSize: isPcSize ? '18px' : '14px',
                                                    }, children: workspace && _jsx("span", { children: workspace.name }) }) }), _jsx("div", { style: { flex: 1 }, children: buildSearchBar() })] }))] }), _jsxs(Grid, { item: true, container: true, justifyContent: "flex-end", alignItems: "center", style: { width: 'fit-content' }, children: [!isPcSize && (_jsx("div", { style: { color: '#7E84A3', marginLeft: '8px' }, children: _jsx(IconButton, { onClick: handleClickSearch, color: "inherit", style: { padding: '2px', border: '2px solid #7E84A3' }, children: _jsx(SearchIcon, { style: { fontSize: '18px' } }) }) })), _jsx("div", { style: {
                                            color: '#7E84A3',
                                            marginLeft: isPcSize ? '16px' : '8px',
                                        }, children: _jsx(IconButton, { onClick: () => {
                                                openHelpPage(helpId);
                                            }, color: "inherit", style: { padding: '0' }, children: _jsx(HelpOutlineIcon, { style: { fontSize: isPcSize ? '24px' : '32px' } }) }) }), isPcSize && globalState.signedUser && (_jsxs("div", { style: { color: '#131523' }, children: [_jsxs(IconButton, { "aria-label": "account of current user", "aria-controls": "menu-appbar", "aria-haspopup": "true", onClick: handleMenu, className: classes.userIconButton, color: "inherit", children: [_jsx(Avatar, { className: classes.avatar, children: globalState.signedUser.account.name.substring(0, 1) }), _jsx("span", { style: {
                                                            fontSize: 14,
                                                            color: '#131523',
                                                            marginLeft: '8px',
                                                        }, className: "name", children: globalState.signedUser.account.name })] }), _jsxs(Menu, { id: "menu-appbar", anchorEl: anchorEl, anchorOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }, keepMounted: true, transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }, open: openMenu, onClose: () => {
                                                    setAnchorEl(null);
                                                }, children: [_jsx(Link, { to: "/profile", style: {
                                                            color: 'inherit',
                                                            textDecoration: 'none',
                                                        }, children: _jsx(MenuItem, { onClick: () => {
                                                                setAnchorEl(null);
                                                            }, children: "\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB\u5909\u66F4" }) }), _jsx(Link, { to: '/favorite-list', style: {
                                                            color: 'inherit',
                                                            textDecoration: 'none',
                                                        }, children: _jsx(MenuItem, { onClick: () => {
                                                                setAnchorEl(null);
                                                            }, children: "\u304A\u6C17\u306B\u5165\u308A\u30EA\u30B9\u30C8" }) }), _jsx(Divider, {}), _jsx(MenuItem, { onClick: handleSignout, children: "\u30ED\u30B0\u30A2\u30A6\u30C8" })] })] })), globalState.session == undefined && (_jsx(LinkRouter, { to: "/signin", style: { color: '#fff' }, children: "\u30ED\u30B0\u30A4\u30F3" })), !isPcSize && (_jsx("div", { style: { marginLeft: '8px' }, children: _jsx(HamburgerMenu, {}) }))] })] }) }), isShowSearchElemForSp && !isPcSize && (_jsx(Box, { style: {
                        width: '100%',
                        margin: '0 auto',
                        padding: '8px 16px 16px',
                    }, children: _jsx(SearchBar, { autoFocus: true }) }))] }) }));
});
export function FullscreenLoading(props) {
    const classes = useStyles();
    return (_jsx(Backdrop, { className: classes.backdrop, open: props.open, children: _jsx(CircularProgress, { color: "inherit" }) }));
}
export const TopAppBarSpacer = styled.div `
  min-height: 80px;
  @media (max-width: 900px) {
    min-height: 16px;
  }
`;
const APP_NAME = 'Locaop';
export function Head(props) {
    const { title } = props;
    const titleContent = title ? `${title} | ${APP_NAME}` : APP_NAME;
    return (_jsx(Helmet, { defer: false, children: _jsx("title", { children: titleContent }) }));
}
export function NotConnectedGMBAccountInfo(props) {
    const { workspaceUid, clientId, shopId, cid } = props;
    const classes = commonUseStyles();
    return (_jsxs(Paper, { className: classes.paper, children: ["Google\u30D3\u30B8\u30CD\u30B9\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB\u30A2\u30AB\u30A6\u30F3\u30C8\u3068\u672A\u63A5\u7D9A\u3067\u3059\u3002", _jsx(LinkButton, { to: `/a/${workspaceUid}/clients/${clientId}/shops/${shopId}/location`, label: "\u63A5\u7D9A\u3059\u308B" }), cid ? (_jsx("div", { children: _jsxs(Button, { color: "primary", onClick: handleOpenUrl(`https://maps.google.com/maps?cid=${cid}`), children: ["Google\u30DE\u30C3\u30D7\u3067\u8868\u793A\u3059\u308B", _jsx(OpenInNewIcon, { fontSize: 'small' })] }) })) : null] }));
}
function useLogoPath(workspaceUid) {
    const workspace = useCurrentWorkspace(workspaceUid);
    if (process.env.REACT_APP_STAGE === 'stg') {
        return '/logos/stg-logo.png';
    }
    return (workspace === null || workspace === void 0 ? void 0 : workspace.logoPath) || '/logos/logo-colored.png';
}
